import React, { useState } from "react";
import { Link } from "react-router-dom";
import ChatList from "./ChatList";
import Button from "../../shared/Button";
import Refresh from "../../assets/SVG/refresh.svg";
import useMessageContext from "../../hooks/useMessageContext";
import axios from "../../api/axios";
import { Store } from "../../stores/Stores";
import useAuth from "../../hooks/useAuth";

const MessageList = () => {
  const { initialState, filteredState } = useMessageContext();
  const [userList, setuserList] = initialState;
  const [filterUser, setFilterUser] = filteredState;
  const { getAccessToken } = useAuth();
  const token = getAccessToken();
  const [refresh, setRefresh] = React.useState(false);
  const CHAT_LIST_BY_NAME = "/searchUserListAff";
  const [searchText, setSearchText] = useState(""); // State for search text
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

  const { setChats } = Store().chatStore;
  console.log({ userList, filterUser });
  const onRefresh = () => {
    setIsRefreshDisabled(true);
    setRefresh(!refresh);
    setTimeout(() => {
      setIsRefreshDisabled(false);
    }, 4000);
  };

  const handleSearch = async () => {
    if (searchText !== "") {
      try {
        const data = { query: searchText };
        const response = await axios.post(CHAT_LIST_BY_NAME, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("ressaa", response);
        if (response && response.data.result === 1) {
          setChats(response.data.data);
        }
      } catch (e) {
        console.log("error", e);
      }
    }
    // Perform search filtering based on searchText
  };

  return (
    <>
      <header className="fixed top-0 inset-x-0 z-50 bg-white overflow-hidden flex flex-wrap justify-between items-center p-3 sm:p-5">
        <h1 className="text-black text-lg sm:text-2xl font-bold">Messages</h1>
        <div className="flex flex-wrap items-center gap-2 sm:gap-4 mt-2 sm:mt-0">
          <Link to="/unread">
            <button
              type="button"
              className="border border-red-500 text-red-500 py-2 px-4 rounded-full text-sm sm:text-base font-bold"
            >
              UnRead Messages
            </button>
          </Link>

          {/* Add search input and button */}
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search messages..."
            className="flex-grow py-2 px-4 rounded-full border border-gray-300 text-sm sm:text-base"
          />
          <button
            type="button"
            onClick={handleSearch}
            className=" text-white py-2 px-4 rounded-full text-sm sm:text-base font-bold"
            style={{ background: "#e70937" }}
          >
            Search
          </button>
        </div>
        <div className="flex items-center gap-2 sm:gap-4 mt-2 sm:mt-0">
          <Button
            className="w-8 sm:w-[2em] hover:scale-[0.85] transition-transform"
            m=""
            onClick={onRefresh}
            disabled={isRefreshDisabled}
          >
            <img src={Refresh} alt="refresh icon" />
          </Button>

          <Link
            onClick={() => {
              setuserList([]);
              setFilterUser([]);
            }}
            to="/broadcast"
            className="text-black text-sm sm:text-lg font-medium"
          >
            Broadcast
          </Link>
        </div>
      </header>

      <ChatList refresh={refresh} setRefresh={setRefresh} />
    </>
  );
};

export default MessageList;
