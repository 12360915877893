import React, { useState, useRef } from "react";
import ChatListEN from "./ChatListEN";
import useMessageContext from "../../hooks/useMessageContext";
import ChatListIN from "./ChatListIN";

const ChatList = () => {
  const { initialState, filteredState } = useMessageContext();
  const [userList, setuserList] = initialState;
  const [filterUser, setFilterUser] = filteredState;
  const scrollElement = useRef(null);
  const [focusLang, setFocusLang] = useState(2); // 1 for English, 2 for Other

  console.log(userList[0]?.name);
  console.log(filterUser[0]?.name);

  function getChatList() {
    if (focusLang === 1) {
      return <ChatListEN scrollElement={scrollElement} />;
    }
    return <ChatListIN scrollElement={scrollElement} />; // Handles "Other Users"
  }

  // Define a common style for tabs to avoid repetition
  const tabStyle = (isActive) => ({
    width: "50%",
    height: "100%",
    backgroundColor: isActive ? "#e71a4d" : "#ffffff",
    color: isActive ? "#ffffff" : "#e71a4d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: isActive ? "bold" : "normal",
    cursor: "pointer",
    border: isActive ? "2px solid #a50e3c" : "2px solid #e71a4d",
    boxSizing: "border-box",
    transition: "background-color 0.3s, color 0.3s, border 0.3s",
  });

  return (
    <section
      className="h-screen overflow-y-scroll px-5 pt-5 absolute top-73px bottom-73px inset-0"
      ref={scrollElement}
    >
      <section
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "50%", // Adjust width since there are now only two tabs
            height: "50px",
            borderRadius: "5px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "2px solid #e71a4d",
            marginBottom: "10px",
          }}
        >
          <div
            role="button"
            style={tabStyle(focusLang === 1)}
            onClick={() => {
              if (focusLang !== 1) {
                setFocusLang(1);
                setFilterUser([]);
                setuserList([]);
              }
            }}
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setFocusLang(1);
                setFilterUser([]);
                setuserList([]);
              }
            }}
          >
            English Users
          </div>
          <div
            role="button"
            style={tabStyle(focusLang === 2)}
            onClick={() => {
              if (focusLang !== 2) {
                setFocusLang(2);
                setFilterUser([]);
                setuserList([]);
              }
            }}
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setFocusLang(2);
                setFilterUser([]);
                setuserList([]);
              }
            }}
          >
            Other Users (GE)
          </div>
        </div>
      </section>
      {getChatList()}
    </section>
  );
};

export default ChatList;
