/* eslint-disable */
import React, { useState } from "react";
import Moment from "react-moment";
import fallbackImg from "../../assets/SVG/profilePic.svg";
import locksvg from "../../assets/SVG/icon8lock.svg";
import gallery from "../../assets/PNG/gallery1.png";
import Button from "../../shared/Button";

const Avatar = ({ avatar, isLoading, setisLoading }) => (
  <div className="relative">
    <img
      className="w-14 h-14 rounded-full"
      src={avatar !== "" ? avatar : fallbackImg}
      alt={avatar !== "" ? avatar : "no image"}
      onLoad={() => setisLoading(false)}
    />
    {/* <span
      className={`absolute top-35px -right-5px w-3 h-3 bg-primary-lightPink box-content border-3px border-white rounded-full ${
        !isLoading && !isRead && received ? 'block' : 'hidden'
      }`}
    /> */}
    {isLoading && (
      <div className="w-14 h-14 rounded-full animate-pulse bg-gray-veryLight absolute top-0" />
    )}
  </div>
);

const Message = ({
  lastMessage,
  name,
  type,
  isRead,
  received,
  isSubscribed,
}) => {
  let text;
  let placeholder;
  if (lastMessage.isLocked) {
    text = "locked-message";
  } else {
    text =
      lastMessage.text && lastMessage.text.length > 18
        ? `${lastMessage.text.slice(0, 18)} ...`
        : lastMessage.text;
  }

  if (type === "image") placeholder = "Photo";
  else if (type === "audio") placeholder = "Voice message";
  else if (type === "video") placeholder = "Video";
  return (
    <div className="pl-4 flex flex-col justify-center">
      <h1 className="text-xl font-semibold text-black text-left">
        {name + " "}
        {isSubscribed ? (
          <span
            style={{
              backgroundColor: "lightblue",
              color: "#e70f3e",
              marginLeft: "10px",
              borderRadius: "20px",
              padding: "5px",
              fontSize: "15px",
            }}
          >
            Subscribed
          </span>
        ) : null}
      </h1>
      <p
        className={`${
          !isRead && received
            ? "text-black font-semibold"
            : "text-gray-mild font-normal"
        }`}
      >
        {type === "image" || type === "video" || type === "audio" ? (
          <span className="flex items-center">
            <img className="w-4 h-4 mr-0.5" src={gallery} alt="gallery" />
            {placeholder}
          </span>
        ) : (
          <span className="flex items-center">
            <img
              className={`w-4 h-4 mr-0.5  ${
                lastMessage.isLocked ? "block" : "hidden"
              }`}
              src={locksvg}
              alt="lock"
            />
            {text}
          </span>
        )}
      </p>
    </div>
  );
};

const BroadcastChatPanel = ({
  avatar,
  name,
  lastMessage,
  isSubscribed,
  userId,
  // userList,
  setuserList,
  isSelected,
}) => {
  // console.log(selectedUsers)
  const [isLoading, setisLoading] = useState(true);
  const isCardSelected = isSelected ? "bg-[#e7002c29]" : "";

  const handleSelectUser = () => {
    setuserList((prevUserList) =>
      prevUserList.map((user) =>
        user.userId === userId
          ? { ...user, isSelected: !user.isSelected }
          : user,
      ),
    );
  };
  return (
    <div style={{ width: '1200px'}}>

    <Button
      onClick={handleSelectUser}
      className={`btn ${isCardSelected}  w-full flex items-center text-center font-bold py-4 text-white px-3
      `}
    >
      <Avatar
        avatar={avatar}
        isLoading={isLoading}
        setisLoading={setisLoading}
        isRead={lastMessage.isRead}
        received={lastMessage.fromUser === userId}
      />
      <Message
        isSubscribed={isSubscribed}
        name={name}
        lastMessage={lastMessage}
        type={lastMessage.type}
        isRead={lastMessage.isRead}
        received={lastMessage.fromUser === userId}
      />
      <Moment
        fromNow
        className={`mt-1 pt-px text-xxs leading-xxs font-bold ml-auto
        ${!lastMessage.isRead && lastMessage.fromUser === userId
            ? "text-black"
            : "text-gray-mild"
          }`}
      >
        {lastMessage.updatedAt}
      </Moment>
    </Button>
    </div>

  );
};

export default BroadcastChatPanel;
