import React, { useState } from "react";
import styles from "./Style.module.css";

const NotesModal = ({ isOpen, onClose, onSend }) => {
  const [note, setNote] = useState("");

  const handleSend = () => {
    if (note.trim()) {
      onSend(note);
      setNote("");
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Create New Note</h2>
        <input
          type="text"
          className={styles.input}
          placeholder="Write your note here..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <div className={styles.actions}>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={styles.sendButton}
            onClick={handleSend}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotesModal;
