import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactMediaRecorder } from "react-media-recorder";
import Compressor from "compressorjs";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import socket from "../socket/socket";
import BackBtn from "../shared/BackBtn";
import sendBtn from "../assets/SVG/send.svg";
import addMediaBtn from "../assets/SVG/addMedia.svg";
import closeMediaBtn from "../assets/SVG/closeMedia.svg";
import AutoTextbox from "../shared/AutoTextbox";
import voiceBtn from "../assets/SVG/voiceIcon.svg";
import galleryBtn from "../assets/SVG/galleryIcon.svg";
import IOSButton from "../shared/IOSButton";
import IOSDialog from "../shared/IOSDialog";
import SentMessage from "../components/messages/SentMessage";
import ReceivedMessage from "../components/messages/ReceivedMessage";
import Typing from "../components/messages/Typing";
import Preview from "../components/messages/Preview";
import getThumbnailForVideo from "../components/messages/getThumbailForVideo";
import fallbackImg from "../assets/SVG/profilePic.svg";
import LockSwitch from "../shared/LockSwitch";
import CapturePhoto from "../components/messages/CapturePhoto";
import CaptureVideo from "../components/messages/CaptureVideo";
import AudioPlayer from "../components/messages/AudioPlayer";
import CoinModal from "../components/messages/CoinModal";
import AlertBox from "../components/alert/AlertBox";
import CoinIcon from "../assets/SVG/Coin.svg";
import optionsIcon from "../assets/SVG/options.svg";
import ChatDetails from "../components/messages/ChatDetails";
// import TranslateSwitch from "../shared/TranslateSwitch";
import TextModal from "../components/messages/TextModal";
import Loader from "../shared/loader/Loader";
import CurrentProfileModal from "../components/profile/CurrentProfileModal";
import { Store } from "../stores/Stores";
import NotesModal from "../components/NoteModal";

// -------------- Header --------------
const Header = ({
  handleClick,
  user,
  status,
  coins,
  freeCount,
  onTogglePriceModal,
  headerMenuOpen,
  calculateAge,
  setHeaderMenuOpen,
  setDetailsOpen,
  messagePrice,
  language,
  dobUser,
}) => (
  <header
    className="fixed select-none top-0 inset-x-0 z-30 bg-white shadow-md font-sans"
    style={{ width: "80%" }}
  >
    <div className="flex justify-between items-center px-4 py-3">
      <div className="flex items-center space-x-4">
        <BackBtn handleClick={handleClick} />

        <button
          type="button"
          onClick={() => setDetailsOpen({ open: true, carousel: false })}
          className="focus:outline-none"
        >
          <img
            className="w-10 h-10 rounded-full"
            src={user.avatar || fallbackImg}
            alt="User avatar"
          />
        </button>
        <button
          type="button"
          onClick={() => setDetailsOpen({ open: true, carousel: false })}
          className="focus:outline-none"
        >
          <div>
            <h2 className="font-semibold text-lg truncate">{user.name}</h2>

            <p
              className={`text-xs ${
                status ? "text-green-500" : "text-gray-500"
              }`}
            >
              {status ? "Online" : "Offline"}
            </p>
          </div>
        </button>
      </div>

      <div id={1} className="bg-PurpleGradient p-1 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <button
            type="button"
            className="w-[1rem] h-[1rem] bg-white rounded-full p-0.5 mr-1"
            onClick={() => setHeaderMenuOpen(!headerMenuOpen)}
          >
            <img src={optionsIcon} alt="Menu" className="w-full h-full" />
          </button>
          {user?.role === "affiliate" ? (
            <div className="bg-white rounded-lg p-2">Affiliate</div>
          ) : (
            <>
              <div className="flex flex-col mr-4">
                <span className="text-white text-sm mt-1">
                  <div className="p-1 bg-white text-red-500 font-bold rounded-full">
                    {language}
                  </div>
                </span>
              </div>
              <div className="flex flex-col mr-4">
                <span className="text-white text-sm">
                  Free Msg: {freeCount}
                </span>
                <span className="text-white text-sm mt-1">
                  Msg Price: {messagePrice}
                </span>
                <span className="text-white text-sm mt-1">
                  Age: {calculateAge(dobUser)}
                </span>
              </div>
              <div className="flex items-center text-white">
                <img src={CoinIcon} alt="Coin" className="w-4 h-4 mr-2" />
                <span className="text-sm">{coins}</span>
              </div>
            </>
          )}
        </div>
        {headerMenuOpen && coins >= 0 && (
          <button
            type="button"
            className="mt-2 bg-white text-PurpleGradient font-medium rounded-md text-sm px-4 py-1 w-full block"
            onClick={() => onTogglePriceModal()}
          >
            Adjust Price
          </button>
        )}
      </div>
    </div>
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.2)",
        position: "absolute",
        bottom: "-20px",
        padding: "2px 5px",
        userSelect: "text",
      }}
    >
      <p style={{ fontSize: "10px", cursor: "text" }}>ID: {user.id}</p>
    </div>
  </header>
);

// -------------- Footer --------------
const Footer = ({
  children,
  toggleMedia,
  openGallery,
  sendAudio,
  textboxFocused,
  isLocked,
  openClooudModal,
  setisLocked,
  translateText,
  value,
  setValue,
}) => {
  const [audioFile, setaudioFile] = useState(null);
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
      type: "audio/mp3",
      blobPropertyBag: {
        type: "audio/mp3",
      },
      askPermissionOnMount: false,
      onStop: (blobUrl, blob) => {
        console.log(blobUrl, blob);
        const myFile = new File([blob], "voice.mp3", {
          type: "audio/mp3",
        });
        setaudioFile(myFile);
      },
    });

  const startVoiceRecording = (event) => {
    event.stopPropagation();
    event.preventDefault();
    startRecording();
  };
  const stopAndSend = async (event) => {
    console.log("***Jeddak");
    event.stopPropagation();
    event.preventDefault();
    stopRecording();
  };
  useEffect(() => {
    console.log("footer");
    if (audioFile === null) {
      setisLocked(false);
    }
  }, [audioFile]);

  return (
    <footer
      className="bottom-0 bg-white shadow-lightBlack absolute w-full overflow-hidden px-5 py-4 font-sans"
      style={{ width: "80%" }}
    >
      {textboxFocused ? (
        <div className="flex justify-center pb-5 space-x-10">
          {value ? (
            <button
              type="button"
              onClick={() =>
                translateText(value, "english", "german").then(
                  (translatedTexte) => setValue(translatedTexte),
                )
              }
            >
              Translate
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                alert("Please type somthing to transate !");
              }}
              style={{ color: "gray" }}
            >
              Translate
            </button>
          )}

          <LockSwitch
            locked={isLocked}
            setlocked={setisLocked}
            className="mt-4"
          />
        </div>
      ) : (
        <div
          className={`justify-center space-x-[78px] flex pb-5 text-xs ${
            toggleMedia ? "block" : "hidden"
          } ${audioFile && "hidden"}`}
        >
          <button
            type="button"
            onClick={openClooudModal}
            className="space-y-1 flex flex-col items-center"
          >
            <img src={galleryBtn} alt="Gallery.svg" />
            <p>Cloud Media</p>
          </button>
          <button
            contextMenu="false"
            type="button"
            onTouchStart={startVoiceRecording}
            onTouchMove={startVoiceRecording}
            onTouchEnd={stopAndSend}
            onMouseDown={startVoiceRecording}
            onMouseUp={stopAndSend}
            className="space-y-1 flex flex-col items-center focus:outline-none"
          >
            <div className="relative flex justify-center items-center">
              <img
                className="pointer-events-none h-8 w-8 z-30"
                src={voiceBtn}
                alt="voice.svg"
              />
              <div
                className={`absolute w-7 h-7 bg-PurpleGradient rounded-full z-20 ${
                  status === "recording" && "animate-ping"
                }`}
              />
            </div>
            <p className="pointer-events-none">
              {status === "recording" ? status : "Voice"}
            </p>
          </button>
          <button
            type="button"
            onClick={openGallery}
            className="space-y-1 flex flex-col items-center"
          >
            <img src={galleryBtn} alt="Gallery.svg" />
            <p>Gallery</p>
          </button>
        </div>
      )}
      {audioFile !== null ? (
        <>
          <div className="flex justify-center pb-5">
            <LockSwitch locked={isLocked} setlocked={setisLocked} />
          </div>
          <AudioPlayer
            sendAudio={sendAudio}
            setaudioFile={setaudioFile}
            audioFile={audioFile}
            audioUrl={mediaBlobUrl}
            clearBlobUrl={clearBlobUrl}
          />
        </>
      ) : (
        children
      )}
    </footer>
  );
};

// -------------- Chat Component --------------
const Chat = () => {
  const [value, setvalue] = useState("");
  const [recipient, setrecipient] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isModalNotesOpen, setIsModalNotesOpen] = useState(false);
  const [messages, setmessages] = useState([]);
  const [messagePrice, setMessagePrice] = useState();
  const [isTyping, setisTyping] = useState(false);
  const [onlineStatus, setonlineStatus] = useState(null);
  const [loadMessage, setLoadMessage] = useState(false);
  const { getAccessToken, getUserId } = useAuth();
  const token = getAccessToken();
  const userId = getUserId();
  const [isLoading, setisLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const galleryRef = useRef(null);
  const [file, setfile] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [thumbnail, setthumbnail] = useState(null);
  const [isImageModal, setIsImageModal] = useState(false);
  const [isImageModalUrl, setIsImageModalUrl] = useState("");
  const loadingRef = useRef(false);
  const scrollRef = useRef(null);
  const prevScrollHeightRef = useRef(0);
  const prevScrollTopRef = useRef(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [detailsOpen, setDetailsOpen] = useState({
    open: false,
    carousel: false,
  });
  const [isOpen, setisOpen] = useState({
    gallery: false,
    voice: false,
  });
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [showPreview, setshowPreview] = useState(false);
  const [toggleMedia, settoggleMedia] = useState(false);
  const [openCamera, setopenCamera] = useState({
    photo: false,
    video: false,
  });
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [textboxFocused, settextboxFocused] = useState(false);
  const [isLocked, setisLocked] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState({});
  const [editedMessage, setEditedMessage] = useState("");
  const [fileSizeAlert, setfileSizeAlert] = useState(false);
  const [endReached, setendReached] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [lastIndex, setlastIndex] = useState(0);
  const [msgCount, setMsgCount] = useState(0);
  const [lang, setLang] = useState("wait");
  const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
  const [diamonds, setDiamonds] = useState(0);
  const [dobUser, setDobUser] = useState("");
  const [openCoinModal, setopenCoinModal] = useState(false);
  const [cloudModal, setCloudModal] = useState(false);
  const { setChats, chats } = Store().chatStore;
  const [modal, setmodal] = useState("");
  const [coins, setcoins] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;
  const CHAT_URL = "/chat/aff";
  const GET_NOTES = "/aff/getNotes";
  const SEND_NOTES = "/aff/creatNotes";
  const DEL_NOTES = "/aff/delNotes";
  const UPLOAD_MEDIA_URL = "/chat/upload";
  const CHAT_READ_URL = "/chat/read";
  const UPLOAD_AUDIO_URL = "/chat/upload-audio";
  const CHANGE_MESSAGE_PRICE_URL = "/chat/message-price";

  // -- NEW STATES FOR RANGE PAGINATION --
  const [nextCursor, setNextCursor] = useState(null); // Tracks older messages
  const [hasMore, setHasMore] = useState(true); // Whether more messages are available

  // -------------- Helpers / Misc --------------
  const handleCloseModalImage = () => {
    setIsImageModal(false);
    setIsImageModalUrl("");
  };
  const handleOpenModalImage = (url) => {
    setIsImageModalUrl(url);
    setIsImageModal(true);
  };
  function calculateAge(dob) {
    if (!dob) {
      return "undefined";
    }
    const dobDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dobDate.getDate())
    ) {
      age = -1;
    }
    return age;
  }
  const openClooudModal = () => {
    setCloudModal(!cloudModal);
    const namee = "cloud";
    setmodal(namee);
  };
  const goBack = () => {
    navigate(-1);
  };
  const setMsgPrice = async (price) => {
    const data = {
      price,
      userId: id,
    };
    const response = await axios.put(CHANGE_MESSAGE_PRICE_URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.price) {
      setMessagePrice(response.data.price);
    }
  };
  const openGalleryDialog = () => {
    setisOpen((prev) => ({ ...prev, gallery: !prev.gallery }));
  };
  const openVoiceDialog = () => {
    setisOpen((prev) => ({ ...prev, voice: !prev.voice }));
  };
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  const updateUnreadChat = async (readId) => {
    console.log("marked as read", readId);
    const res = await axios.post(
      CHAT_READ_URL,
      { readId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (res) console.log(res);
  };

  // -------------- handleChatData --------------
  // Distinguish between first load (no cursor) vs loading older (cursor present)
  const handleChatData = (res, cursor) => {
    if (!cursor) {
      // First fetch (newest messages)
      setrecipient(res?.data);
      const reversedMsg = res?.data?.messages?.reverse() || [];
      setmessages(reversedMsg);
      setisLoading(false);
      setMsgCount(res?.data.count || 0);

      if (res?.data?.language === "N/A") {
        setLang(
          res?.data?.country === "germany" || res?.data?.country === "de"
            ? "de"
            : "en",
        );
      } else {
        setLang(res?.data?.language);
      }
      setMessagePrice(res?.data?.messagePrice);
      setDiamonds(res?.data.diamonds || 0);
      scrollToBottom();
    } else {
      // Loading older messages
      setendReached(false);
      setrecipient((prev) => ({ ...res.data, ...prev }));
      const reversedMsg = res?.data?.messages?.reverse() || [];
      // Prepend older messages
      setmessages((prev) => [...reversedMsg, ...prev]);
      setlastIndex(res?.data?.messages.length);
    }
  };

  // -------------- fetchUserChat --------------
  // Now uses lastCreatedAt (cursor) instead of page
  const fetchUserChat = async (cursor = null) => {
    // console.log(
    //   "fetch from api -> costly. lastCreatedAt:",
    //   cursor ? cursor : "latest",
    // );
    setIsFetchingMore(true);

    const data = {
      userID: id,
      // if we have a cursor, send it to load older
    };
    if (cursor) {
      data.lastCreatedAt = cursor;
    }

    try {
      const res = await axios.post(CHAT_URL, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res) {
        // e.g. res.data => { messages, nextCursor, hasMore, ... }
        if (res.data.diamonds) {
          setDobUser(res.data.dob);
          setDiamonds(res.data.diamonds);
        }
        const messagesRes = res?.data?.messages || [];

        if (messagesRes.length > 0) {
          handleChatData(res, cursor);
          // Store the new cursor
          setNextCursor(res.data.nextCursor || null);
          setHasMore(res.data.hasMore);
        } else {
          // No messages found
          if (!cursor) {
            // new chat scenario
            setmessages([]);
            setisLoading(false);
            scrollToBottom();
          }
          setHasMore(false);
          setendReached(true);
        }
      }
    } catch (err) {
      console.log("error", err);
    }

    setIsFetchingMore(false);
  };

  const getNotes = async () => {
    const data = {
      toUser: id,
    };

    try {
      const res = await axios.post(GET_NOTES, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("ggg", res);
      if (res && res.data.result === 1) {
        setNotesData(res.data.notes.notes);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const sendNotes = async (note) => {
    const data = {
      toUser: id,
      note,
    };
    console.log("notess", data);

    try {
      const res = await axios.post(SEND_NOTES, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("nnn", res);
      if (res && res.data.result === 1) {
        setNotesData(res.data.note.notes);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const delNotes = async (noteId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this note?",
    );
    if (!confirmDelete) {
      // If the user clicks "Cancel," stop execution.
      return;
    }
    const data = {
      noteId,
    };

    try {
      const res = await axios.post(DEL_NOTES, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("nnn", res);
      if (res) {
        setNotesData(res.data.updatedDocument.notes);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);
  // -------------- Online Status --------------
  const checkOnlineStatus = async () => {
    const res = await axios.get(`/online/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res);
    if (res) setonlineStatus(res?.data?.isOnline);
  };

  // -------------- Deletion / Editing --------------
  const handleDeleteMessage = async (messageID) => {
    try {
      const res = await axios.post(`/message-delete/${messageID}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
      if (res.data.success) {
        // remove the message from state if you want
      } else {
        // handle error
      }
    } catch (err) {
      console.error("Error deleting message", err);
    }
  };
  const handleEditMessage = async (messageId, newText) => {
    try {
      const res = await axios.post(
        `/message-edit/${messageId}`,
        { newText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(res);
      if (res.data.success) {
        alert("message edited successfully");
      } else {
        // handle error
      }
    } catch (err) {
      console.error("Error editing message", err);
      alert(err);
    }
  };

  // -------------- Translation (unchanged) --------------
  const translateText = async (txt, sourceLang, targetLang, glossaryId) => {
    console.log("targetLang", targetLang);
    console.log("sourceLang", sourceLang);
    console.log("glossaryId", glossaryId);
    try {
      const requestHeaders = {
        accept: "/",
        "accept-language": `${targetLang},${sourceLang};q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6`,
        "content-type": "application/json",
        "ngrok-skip-browser-warning": "true",
        "sec-ch-ua":
          '"Chromium";v="118", "Microsoft Edge";v="118", "Not=A?Brand";v="99"',
        "sec-ch-ua-mobile": "?1",
        "sec-ch-ua-platform": '"Android"',
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
        Referer: "https://4based.com/",
        "Referrer-Policy": "strict-origin-when-cross-origin",
      };

      const requestBody = JSON.stringify({
        input: txt,
        target: targetLang.toLowerCase(),
      });

      const response = await fetch(
        "https://seagull-allowed-ocelot.ngrok-free.app/translate",
        {
          method: "POST",
          headers: requestHeaders,
          body: requestBody,
        },
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        if (jsonResponse.result) {
          const translatedText = jsonResponse.result;
          console.log(translatedText);
          return translatedText;
        }
        console.error("Translation result not found in response.");
      } else {
        console.error("Translation failed with status code:", response.status);
      }
      return "Translation failed";
    } catch (err) {
      console.error("Translation error:", err);
      return "Translation error";
    }
  };

  // -------------- Render Messages --------------
  const generateMessages = (msg) => {
    let component;
    let showMessage = true;

    // For broadcast logic:
    if (msg.isBroadcastMSG && !msg.isBroadcastExpired) {
      showMessage = false;
    }

    if (showMessage) {
      if (msg.fromUser === id) {
        component = (
          <ReceivedMessage
            mediaSrc={msg.downloadURL ? msg.downloadURL : msg.video}
            id={msg?._id}
            type={msg?.type}
            key={msg?._id}
            isTip={msg?.isTip}
            isLocked={msg?.isLocked}
            coins={msg?.diamonds ? msg.diamonds : msg.coins}
            date={msg?.createdAt}
            displayPic={recipient.userImage}
            translateText={translateText}
            deleteMessage={handleDeleteMessage}
          >
            {msg.text}
          </ReceivedMessage>
        );
      } else {
        component = (
          <SentMessage
            mediaSrc={msg.downloadURL ? msg.downloadURL : msg.video}
            type={msg?.type}
            key={msg._id ? msg._id : msg.downloadURL}
            id={msg?._id}
            date={msg?.createdAt}
            isLocked={msg?.isLocked}
            wasLocked={msg?.wasLocked}
            coins={msg?.diamonds ? msg.diamonds : msg.coins}
            translateText={translateText}
            deleteMessage={handleDeleteMessage}
            fetchUserChat={fetchUserChat}
            editMessage={handleEditMessage}
            setMessageToEdit={setMessageToEdit}
            setIsEdited={setIsEdited}
            editedMessage={editedMessage}
          >
            {msg.text}
          </SentMessage>
        );
      }
    }
    return component;
  };

  // -------------- Sending Text --------------
  const onSend = () => {
    setLoadMessage(true);
    console.log("Text", value);
    console.log("userId", userId);
    console.log("locked", isLocked);
    if (value.length === 0) {
      alert("Type somthing");
      setLoadMessage(false);
      return;
    }
    socket.emit("message", {
      text: value,
      userID: id,
      type: "text",
      isLocked,
      coins,
    });
    setmessages((prev) => [
      ...prev,
      {
        text: value,
        fromUser: userId,
        type: "text",
        isLocked,
        coins,
      },
    ]);
    setisLocked(false);
    setcoins(0);
    setvalue("");
    document.querySelector("#MessageTextBox").textContent = "";
    setLoadMessage(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSend();
    }
  };

  // -------------- Sending Audio --------------
  const sendAudio = async (audioFile, audioUrl) => {
    let fileTemp;
    if (audioFile !== null) {
      console.log(audioFile);
      const type = audioFile.type.split("/")[0];
      const audioformData = new FormData();
      audioformData.append("file", audioFile);
      audioformData.append("userID", id);
      audioformData.append("isLocked", isLocked);
      audioformData.append("coins", coins);
      if (audioformData) {
        fileTemp = {
          fromUser: userId,
          downloadURL: audioUrl,
          type,
          isLocked,
          coins,
        };
        console.log(fileTemp);
        const response = await axios.post(UPLOAD_AUDIO_URL, audioformData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);

        if (response) {
          setmessages((prev) => [...prev, fileTemp]);
          setcoins(0);
          setisLocked(false);
        }
      }
    }
  };

  // -------------- Sending Media (Images/Videos) --------------
  const openFileManager = () => {
    galleryRef.current.click();
  };
  const sendMedia = async (locked) => {
    setisOpen((prev) => ({ ...prev, gallery: !prev.gallery }));
    let fileTemp;
    if (file !== null) {
      console.log(file, typeof coins, typeof locked);
      const type = file.type.split("/")[0];
      const formData = new FormData();

      formData.append("file", file);
      formData.append("userID", id);
      formData.append("isLocked", locked);
      formData.append("coins", coins);

      if (formData) {
        if (type === "image") {
          fileTemp = {
            fromUser: userId,
            downloadURL: fileUrl,
            type,
            isLocked: locked,
            coins,
          };
        } else {
          fileTemp = {
            fromUser: userId,
            video: {
              video: fileUrl,
              thumbnail,
            },
            type: "video",
            isLocked: locked,
            coins,
          };
        }

        try {
          setmessages((prev) => [...prev, fileTemp]);

          const response = await axios.post(UPLOAD_MEDIA_URL, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setUploadProgress(percentCompleted);
            },
          });
          console.log("response chat upload", response);
          if (response) {
            console.log("Success");
            scrollToBottom();
            URL.revokeObjectURL(file);
            setcoins(0);
            setisLocked(false);
            setfile(null);
            setUploadProgress(0);
          }
        } catch (e) {
          console.log("Error");
          alert(`Error: ${e}`);
          setfile(null);
          return false;
        }
      } else return false;
    }
    return false;
  };
  const closeFileupload = () => {
    console.log("Close file upload");
    if (file !== null) {
      setfile(null);
      URL.revokeObjectURL(file);
      setfileUrl(null);
      setshowPreview(false);
    }
  };

  // -------------- Infinite Scroll --------------
  // When scrolled to top, load older messages if hasMore is true
  const scrollEnd = async (scroller) => {
    if (scroller.scrollTop === 0 && !loadingRef.current && hasMore) {
      loadingRef.current = true;

      prevScrollHeightRef.current = scroller.scrollHeight;
      prevScrollTopRef.current = scroller.scrollTop;

      console.log("Hit top, fetching older messages");
      // Call fetchUserChat with the current nextCursor
      await fetchUserChat(nextCursor);

      const newScrollTop =
        scroller.scrollHeight -
        prevScrollHeightRef.current +
        prevScrollTopRef.current;

      scroller.scrollTo(0, newScrollTop);
      loadingRef.current = false;
    }
  };

  // -------------- Socket Setup --------------
  const useSocket = () => {
    socket.on("connect_error", (error) => {
      console.error("Connection Error:", error);
    });
    socket.on("reconnect_failed", () => {
      console.log("Reconnection Failed");
    });
    socket.on("disconnect", (reason) => {
      console.log("Disconnected:", reason);
    });
    socket.on("message", (msg) => {
      if (msg.fromUser && msg.fromUser === id) {
        console.log("messages", msg);
        setmessages((prev) => [...prev, msg]);
        updateUnreadChat(id);
      }
    });
    socket.on("startTyping", (msg) => {
      if (msg === id) {
        setisTyping(true);
        scrollToBottom();
      }
    });
    socket.on("stopTyping", () => {
      setisTyping(false);
    });
    socket.on("online", () => {
      setonlineStatus(true);
    });
    socket.on("offline", () => {
      setonlineStatus(false);
    });
    socket.on("messageCount", (res) => {
      const diamnds = res.diamonds;
      const { count } = res;
      setMsgCount(count);
      setDiamonds(diamnds);
    });
    socket.on("media", (msg) => {
      console.log("Media", msg);
      setmessages((prev) => [...prev, msg]);
      updateUnreadChat(id);
    });
    socket.on("audio", (msg) => {
      console.log("Audio", msg);
      setmessages((prev) => [...prev, msg]);
      updateUnreadChat(id);
    });
  };

  // -------------- Effects --------------
  useEffect(() => {
    if (value.length === 0) {
      socket.emit("stopTyping", { userId: id });
    } else {
      socket.emit("startTyping", { userId: id });
    }
    const timer = setTimeout(async () => {
      socket.emit("stopTyping", { userId: id });
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  useEffect(() => {
    if (file !== null) {
      if (file.size / 10000000 ** 2 > 500) {
        setfileSizeAlert(true);
      } else {
        setfileUrl(URL.createObjectURL(file));
        if (!openCamera.photo && !openCamera.video) {
          setshowPreview(true);
        }
      }
    }
  }, [file]);

  useEffect(() => {
    if (file !== null && file.type.split("/")[0] === "video") {
      const getThumb = async () => {
        const response = await getThumbnailForVideo(fileUrl);
        console.log(response);
        setthumbnail(response);
      };
      getThumb();
    }
  }, [fileUrl]);

  useEffect(() => {
    if (isLocked) setopenCoinModal(true);
  }, [isLocked]);

  useEffect(() => {
    scrollToBottom();
    const scroller = document.querySelector("#scrollId");
    scroller.addEventListener("scroll", () => scrollEnd(scroller), {
      passive: true,
    });
    return () => {
      scroller.removeEventListener("scroll", scrollEnd, { passive: true });
    };
  }, [isLoading, messages]);

  // Initially fetch newest messages (no cursor):
  useEffect(() => {
    console.log("Rerender");
    document.addEventListener("contextmenu", (e) => e.preventDefault());
    useSocket();
    fetchUserChat(null); // first load => newest
    checkOnlineStatus();

    const verifyBlock = async () => {
      const data = {
        userId: id,
      };
      try {
        const res = await axios.post("/user/verifyBlock", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res?.data?.result === 3) {
          setIsInputDisabled(true);
        }
        console.log("Data from verifyBlock:", res.data);
      } catch (error) {
        console.error("Error from verifyBlock:", error.message);
      }
    };
    verifyBlock();

    return () => {
      socket.off("message");
      socket.off("media");
      socket.off("online");
      socket.off("startTyping");
      socket.off("offline");
      socket.off("stopTyping");
    };
  }, []);

  useEffect(() => {
    const handleUpdateUserList = (updatedChat) => {
      console.log("ussssss", updatedChat);
      const existingChatIndex = chats.findIndex(
        (chat) => chat.userId === updatedChat.userId,
      );

      if (existingChatIndex !== -1) {
        const updatedChats = [...chats];
        const [existingChat] = updatedChats.splice(existingChatIndex, 1);
        const updatedChatDetails = { ...existingChat, ...updatedChat };
        setChats([updatedChatDetails, ...updatedChats]);
      } else {
        setChats([updatedChat, ...chats]);
      }
    };
    const handleConnectError = () => {
      console.log("err connect socket");
    };
    console.log("waelll matttt");
    socket.on("updateUserList", handleUpdateUserList);
    socket.on("connect_error", handleConnectError);

    return () => {
      socket.off("updateUserList", handleUpdateUserList);
      socket.off("connect_error", handleConnectError);
    };
  }, [chats]);

  // Debounce scroll, optional:
  const debounceScroll = (func, delay) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };
  useEffect(() => {
    const scroller = document.querySelector("#scrollId");
    const debouncedScrollEnd = debounceScroll(() => scrollEnd(scroller), 200);
    scroller.addEventListener("scroll", debouncedScrollEnd, { passive: true });
    return () => {
      scroller.removeEventListener("scroll", debouncedScrollEnd, {
        passive: true,
      });
    };
  }, [endReached]);

  const handleCalculateAge = (dob) => calculateAge(dob);

  // -------------- Render --------------
  return (
    <div>
      <div>
        <Header
          user={location.state}
          status={onlineStatus}
          language={lang}
          calculateAge={handleCalculateAge}
          handleClick={goBack}
          coins={diamonds}
          freeCount={msgCount}
          dobUser={dobUser}
          setHeaderMenuOpen={setHeaderMenuOpen}
          headerMenuOpen={headerMenuOpen}
          onTogglePriceModal={() => setPriceModalOpen(!priceModalOpen)}
          setDetailsOpen={setDetailsOpen}
          messagePrice={messagePrice}
        />
        <AlertBox isOpen={fileSizeAlert} setIsOpen={setfileSizeAlert}>
          File size cannot exceed 100 MB
        </AlertBox>
        <section
          ref={scrollRef}
          onScroll={(e) => scrollEnd(e.target)}
          id="scrollId"
          className={`px-5 h-auto absolute top-73px space-y-8 bottom-73px inset-0 overflow-y-auto font-sans ${
            toggleMedia ? "pb-20" : "pb-5"
          }`}
          style={{ width: "80%" }}
        >
          {isFetchingMore && (
            <div className="flex justify-center items-center py-3">
              <Loader />
            </div>
          )}
          <div className="mt-5 space-y-5">
            {!isLoading ? (
              messages.map((msg, i) => (
                <div key={msg._id || i}>
                  {generateMessages(msg)}
                  {i === lastIndex - 1 && (
                    <img
                      src={recipient.userImage}
                      onLoad={(e) =>
                        e.currentTarget.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        })
                      }
                      className="w-0 h-0 m-0"
                      alt="fake-scroll"
                    />
                  )}
                </div>
              ))
            ) : (
              <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
            )}
            {isTyping && (
              <ReceivedMessage
                key={recipient.otherName}
                displayPic={recipient.userImage}
                type="text"
              >
                <Typing />
              </ReceivedMessage>
            )}
          </div>
          <div className="pt-10" ref={messagesEndRef} />
        </section>

        {isInputDisabled ? (
          <div>
            <p
              style={{
                position: "fixed",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
                width: "100%",
                backgroundColor: "rgba(255, 0, 0, 0.7)",
                padding: "10px",
                color: "white",
              }}
            >
              You can&apos;t communicate with this user because he/she has
              blocked you.
            </p>
          </div>
        ) : (
          <Footer
            toggleMedia={toggleMedia}
            openGallery={openGalleryDialog}
            sendAudio={sendAudio}
            textboxFocused={textboxFocused}
            isLocked={isLocked}
            setisLocked={setisLocked}
            value={value}
            setValue={setvalue}
            translateText={translateText}
            openClooudModal={openClooudModal}
          >
            <div className="flex space-x-2.5 ">
              {!textboxFocused ? (
                <button
                  type="button"
                  onClick={() => settoggleMedia(!toggleMedia)}
                >
                  {!toggleMedia ? (
                    <img src={addMediaBtn} alt="add media" />
                  ) : (
                    <img src={closeMediaBtn} alt="close" />
                  )}
                </button>
              ) : (
                <button type="button" onClick={() => settextboxFocused(false)}>
                  <img src={closeMediaBtn} alt="close" />
                </button>
              )}
              <AutoTextbox
                onFocus={() => settextboxFocused(true)}
                styles="w-full block px-3 max-h-[82px] min-h-[32px] py-1.5 text-sm align-middle rounded-20px border border-gray-veryLight rounder-[60px] font-sans"
                value={value}
                handleKeyPress={handleKeyPress}
                id="MessageTextBox"
                setvalue={setvalue}
              />
              {loadMessage && <Loader />}

              <button type="button" onClick={onSend}>
                <img src={sendBtn} alt="send button" />
              </button>
            </div>
          </Footer>
        )}
        {detailsOpen.open ? (
          <ChatDetails
            onBack={() => setDetailsOpen(false)}
            avatar={
              location.state?.avatar !== "" ||
              location.state?.avatar !== undefined
                ? location.state?.avatar
                : fallbackImg
            }
            handleCloseModalImage={handleCloseModalImage}
            handleOpenModalImage={handleOpenModalImage}
            isImageModal={isImageModal}
            isImageModalUrl={isImageModalUrl}
            otherUserId={id}
            carouselOpen={detailsOpen.carousel}
          />
        ) : null}
        <IOSDialog gallery isOpen={isOpen.gallery} update={openGalleryDialog}>
          {file && showPreview && (
            <Preview
              thumbnail={thumbnail}
              file={file}
              setcoins={setcoins}
              fileUrl={fileUrl}
              showPreview={showPreview}
              closeFileupload={closeFileupload}
              sendMedia={sendMedia}
            />
          )}
          <IOSButton onClick={openFileManager}>Upload from Gallery</IOSButton>
          <input
            type="file"
            id="file"
            onChange={async (e) => {
              const isImage = !e.target.files[0].type.startsWith("vid");
              if (isImage) {
                const compressed = await new Promise((resolve, reject) => {
                  const compressor = new Compressor(e.target.files[0], {
                    quality: 0.3,
                    maxWidth: 800,
                    maxHeight: 800,
                    success(result) {
                      resolve(result);
                    },
                    error(error) {
                      reject(error);
                    },
                  });
                  console.log("compressor", compressor);
                });
                const compressedFile = new File(
                  [compressed],
                  e.target.files[0].name,
                  {
                    type: compressed.type,
                    lastModified: compressed.lastModified,
                  },
                );
                setfile(compressedFile);
              }
              setfile(e.target.files[0]);
              e.target.files = [];
            }}
            ref={galleryRef}
            className="hidden"
            accept="image/*, video/*"
          />
          <IOSButton
            onClick={() => setopenCamera((prev) => ({ ...prev, video: true }))}
          >
            Take Video
          </IOSButton>
          <IOSButton
            onClick={() => setopenCamera((prev) => ({ ...prev, photo: true }))}
          >
            Take Photo
          </IOSButton>
          {openCamera.photo && (
            <CapturePhoto
              setcoins={setcoins}
              openCamera={openCamera.photo}
              setopenCamera={setopenCamera}
              setfile={setfile}
              sendMedia={sendMedia}
            />
          )}
          {openCamera.video && (
            <CaptureVideo
              setcoins={setcoins}
              openCamera={openCamera.video}
              setopenCamera={setopenCamera}
              setfile={setfile}
              sendMedia={sendMedia}
            />
          )}
        </IOSDialog>
        <IOSDialog isOpen={isOpen.voice} update={openVoiceDialog}>
          <div>hello</div>
        </IOSDialog>
        {priceModalOpen ? (
          <CoinModal
            isOpen={priceModalOpen}
            setisOpen={setPriceModalOpen}
            setcoins={setMsgPrice}
            setisLocked={() => setPriceModalOpen(false)}
            isLimitedPrice
            title="Enter message price by coins"
          />
        ) : null}
        {isEdited ? (
          <TextModal
            isOpen={isEdited}
            setIsOpen={setIsEdited}
            setText={setvalue}
            editMessage={handleEditMessage}
            messageToEdit={messageToEdit}
            setEditedMessage={setEditedMessage}
          />
        ) : null}
        {isLocked && (
          <CoinModal
            isOpen={openCoinModal}
            setisOpen={setopenCoinModal}
            setcoins={setcoins}
            setisLocked={setisLocked}
            title="Enter Unlock amount"
          />
        )}
        {file && uploadProgress < 99 && (
          <div className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2 z-50">
            <div
              style={{
                padding: "10px",
                backgroundImage:
                  "linear-gradient(90deg, #E62E66 0%, #E7002C 100%)",
                color: "#fff",
                borderRadius: "100%",
              }}
            >
              {uploadProgress}%
            </div>
          </div>
        )}

        {file && uploadProgress >= 99 && (
          <Loader className="fixed top-[45%] left-[47%] transform -translate-x-1/2 -translate-y-1/2" />
        )}

        {!file && null}

        {cloudModal && (
          <CurrentProfileModal
            modal={modal}
            isOpen={cloudModal}
            setisOpen={setCloudModal}
            id={id}
            setmessages={setmessages}
            cloudChat="true"
          />
        )}
      </div>
      <div
        style={{
          width: "18%",
          height: "100%",
          position: "absolute",
          right: "0px",
          top: "0px",
          bottom: "0px",
          padding: "10px",
        }}
      >
        <div
          style={{
            background: "gray",
            height: "100%",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          <div
            role="button"
            tabIndex={0}
            style={{ color: "#fff", fontWeight: "bold", fontSize: "18px" }}
            onClick={() => setIsModalNotesOpen(true)}
          >
            🗒️ Notes
          </div>
          {notesData.length > 0 &&
            notesData.map((val) => (
              <div
                role="button"
                tabIndex="-1"
                style={{ color: "#fff", fontSize: "15px" }}
                key={val._id}
                onClick={() => {
                  delNotes(val._id);
                }}
              >
                - {val.note}
              </div>
            ))}
        </div>
      </div>
      <NotesModal
        isOpen={isModalNotesOpen}
        onClose={() => setIsModalNotesOpen(false)}
        onSend={sendNotes}
      />
    </div>
  );
};

export default Chat;
