import React, { useState } from "react";

const ChildSafetyPolicy = () => {
  const [language, setLanguage] = useState("en"); // Default to English

  const content = {
    en: {
      title: "Tapyn Child Safety Policy",
      description:
        "At Tapyn, the safety and well-being of our users are our top priorities. We are committed to protecting users from child sexual abuse material (CSAM) and exploitation.",
      ageRestriction: "Age Restriction (18+)",
      ageRestrictionText:
        "Tapyn is strictly for users aged 18 and above. Any accounts suspected of belonging to minors will be immediately reviewed and removed if necessary.",
      moderation: "Strict Moderation & Content Review",
      moderationText:
        "We utilize both automated detection systems and human moderation to prevent the sharing of illegal or harmful content. Any violation of our child safety policy will result in an immediate ban and reporting to relevant authorities.",
      reporting: "Reporting Inappropriate Content",
      reportingText:
        "Users can report child safety concerns through our in-app reporting system or by contacting our safety team at:",
      compliance: "Compliance with Laws & Reporting",
      complianceText:
        "We fully comply with international child protection laws and cooperate with law enforcement agencies to report any detected CSAM activities.",
      commitment: "Final Commitment",
      commitmentText:
        "Tapyn maintains a zero-tolerance policy toward child exploitation. We actively monitor and improve our security measures to ensure a safe environment for all users.",
      email: "📧 tapyn@sharify.de",
      lastUpdated: "Last updated",
      switchLanguage: "Switch to German",
    },
    de: {
      title: "Tapyn Kindersicherheitsrichtlinie",
      description:
        "Bei Tapyn stehen die Sicherheit und das Wohlbefinden unserer Nutzer an erster Stelle. Wir setzen uns dafür ein, Benutzer vor Materialien zum sexuellen Missbrauch von Kindern (CSAM) und Ausbeutung zu schützen.",
      ageRestriction: "Altersbeschränkung (18+)",
      ageRestrictionText:
        "Tapyn ist ausschließlich für Benutzer ab 18 Jahren vorgesehen. Konten, die Minderjährigen gehören könnten, werden überprüft und gegebenenfalls entfernt.",
      moderation: "Strenge Moderation & Inhaltsprüfung",
      moderationText:
        "Wir nutzen sowohl automatisierte Erkennungssysteme als auch manuelle Moderation, um die Verbreitung illegaler oder schädlicher Inhalte zu verhindern. Verstöße führen zu sofortiger Sperrung und Meldung an die zuständigen Behörden.",
      reporting: "Melden unangemessener Inhalte",
      reportingText:
        "Benutzer können Bedenken hinsichtlich der Kindersicherheit über unser In-App-Meldesystem oder per E-Mail an unser Sicherheitsteam melden:",
      compliance: "Einhaltung von Gesetzen & Berichterstattung",
      complianceText:
        "Wir halten uns vollständig an internationale Kinderschutzgesetze und arbeiten mit Strafverfolgungsbehörden zusammen, um gemeldete CSAM-Aktivitäten zu melden.",
      commitment: "Unser Engagement",
      commitmentText:
        "Tapyn verfolgt eine Null-Toleranz-Politik gegenüber Kindesmissbrauch. Wir überwachen und verbessern kontinuierlich unsere Sicherheitsmaßnahmen, um eine sichere Umgebung für alle Nutzer zu gewährleisten.",
            email: "📧 tapyn@sharify.de",
            lastUpdated: "Zuletzt aktualisiert",
            switchLanguage: "Zu Englisch wechseln",
        },
    };

    return (
        <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <button
                type="button"

                className="bg-blue-600 px-4 py-2 rounded-lg mb-4"
                style={{ color: "black" }}
                onClick={() => setLanguage(language === "en" ? "de" : "en")}
            >
                {content[language].switchLanguage}
            </button>

            <h1 className="text-3xl font-bold text-center mb-4">
                {content[language].title}
      </h1>
      <p className="text-gray-700 mb-4">{content[language].description}</p>

      <h2 className="text-xl font-semibold mt-6">
        {content[language].ageRestriction}
      </h2>
      <p className="text-gray-700">{content[language].ageRestrictionText}</p>

      <h2 className="text-xl font-semibold mt-6">
        {content[language].moderation}
      </h2>
      <p className="text-gray-700">{content[language].moderationText}</p>

      <h2 className="text-xl font-semibold mt-6">
        {content[language].reporting}
      </h2>
      <p className="text-gray-700">{content[language].reportingText}</p>
      <p className="text-blue-600 font-semibold">{content[language].email}</p>

      <h2 className="text-xl font-semibold mt-6">
        {content[language].compliance}
      </h2>
      <p className="text-gray-700">{content[language].complianceText}</p>

      <h2 className="text-xl font-semibold mt-6">
        {content[language].commitment}
      </h2>
      <p className="text-gray-700">{content[language].commitmentText}</p>

      <p className="text-gray-600 text-sm mt-6 text-center">
        {content[language].lastUpdated}: {new Date().toLocaleDateString()}
      </p>
    </div>
  );
};

export default ChildSafetyPolicy;
